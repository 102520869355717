//Api routes
// const BaseUrl = "http://192.168.100.31:4000/website/";
// const BaseUrl = 'https://phpstack-920157-3520511.cloudwaysapps.com/website/';

const BaseUrl =
  process.env.REACT_APP_STAGE === "PROD"
    ? "https://server.servall.co.uk/website/"
    : "http://192.168.1.14:4000/website/";
// const BaseUrl = 'https://server.servall.be/website/';
const BaseUrlWithoutWebsite =
  process.env.REACT_APP_STAGE === "PROD"
    ? "https://server.servall.co.uk/restaurant_data/"
    : "http://192.168.1.14:4000/restaurant_data/";
const BaseUrlForGuestBook =
  process.env.REACT_APP_STAGE === "PROD"
    ? "https://server.servall.co.uk/"
    : "http://192.168.1.14:4000/";
const BaseUrlForWaiter =
  process.env.REACT_APP_STAGE === "PROD"
    ? "https://server.servall.co.uk/"
    : "http://192.168.1.14:4000/";

const socketURL = process.env.REACT_APP_STAGE === "PROD" ? "https://server.servall.co.uk/" : "http://192.168.1.14:4000/"

export const ApiRoute = {
  getLocations: BaseUrl + "locations/list",
  getPremiumServices: (loc_id) => BaseUrl + `services/${loc_id}`,
  getLocationDetail: (slug) => BaseUrl + `locations/${slug}/details`,
  getLocationMenu: (slug) => BaseUrl + `locations/${slug}/menu`,
  getSession: (table_id) => BaseUrl + `session/${table_id}`,
  verifyLocation: (location_id) => BaseUrl + `locations/radius/${location_id}`,
  getPaymentIntent: (loc_id) => BaseUrl + `stripe/getSecret/${loc_id}`,
  getLocationTimeSlots: (slug) => BaseUrl + `timeslots/${slug}`,
  getSessionRoom: (room_id, loc_id) =>
    BaseUrl + `session/room/${room_id}/location/${loc_id}`,
  callWaiter: BaseUrlForWaiter + "locationSessions/call_waiter",
  getPackages: BaseUrl + "packages/all",
  paymentRequest: BaseUrl + "payment_request",
  signUpUser: BaseUrl + "user/create",
  signInUser: BaseUrl + "user/login",
  signInUserWithMail: BaseUrl + "user/login",
  createSession: BaseUrl + "orders/create/session",
  closeSession: BaseUrl + "orders/create/close",
  createOrder: BaseUrl + "orders/create/order",
  createDirectOrder: BaseUrl + "direct_orders/order",
  getSessionHistory: BaseUrl + "orders/history",
  modifyOrder: (session_id) => BaseUrl + `orders/alterWebOrder/${session_id}`,
  addReview: (location_id) => `${BaseUrl}review/hotels/${location_id}`,
  getReviews: (location_id) => `${BaseUrl}review/hotels/approved${location_id}`,

  verifyOtp: (location_id) => `${BaseUrl}verifyOTP/${location_id}`,
  getSessionStatus: (session_id) =>
    `${BaseUrl}orders/sessionStatus/${session_id}`,
  checkTableSession: BaseUrl + "session/checkTableSession",
  createStripeOrder: (license_key) =>
    `${BaseUrl}packages/purchase/${license_key}`,

  getSurveyQuestions: (location_id) =>
    `${BaseUrl}survey/${location_id}/all_questions`,

  getPayment: (location_id) => `${BaseUrl}payment_method/${location_id}`,
  // saveSurveyQuestions: (location_id) =>
  //   BaseUrl + `survey/${location_id}/response`,

  saveSurveyQuestions: (location_id, data) =>
    `${BaseUrl}survey/${location_id}/response`,
  reserveRestaurant: (loc_id) => `${BaseUrl}reservations/add/reservation/${loc_id}`,
  updateUserProfileInfo: `${BaseUrl}user/profile`,
  updatePassword: `${BaseUrl}user/password-reset`,
  getUserOrderHistory: `${BaseUrl}user/orders/history`,
  getUserReservationsHistory: `${BaseUrl}user/reservations/history`,
  deleteUserAccount: `${BaseUrl}user/delete`
};

export const URLS = {
  BaseUrl,
  BaseUrlWithoutWebsite,
  BaseUrlForGuestBook,
  socketURL
};

export const CONSTANTS = {
  REQUEST_HEADERS: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
  },
};
